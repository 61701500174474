import {analytics, db} from './firebase';
import {selectRandomIndex} from "./service/wordGenerator";
import {Language, Team} from "./constants";
import { doc, getDoc, deleteDoc, setDoc, getDocs , onSnapshot, collection} from "firebase/firestore";
import {DocumentData} from '@firebase/firestore-types';
import {DocumentSnapshot} from '@firebase/firestore';
import {logEvent} from "firebase/analytics";

export const updateCurrentTeam = async (keyword: string, language: Language, team: Team) => {
    const gameRef = doc(db, `game_${language}`, keyword);
    await setDoc(gameRef,{currentTeam: team}, {merge: true});
}

export const updateGameStatus = async (keyword: string, language: Language, newData: {words: string[]}) => {
    const timestamp = +new Date();
    const gameRef = doc(db, `game_${language}`, keyword );
    await setDoc(gameRef, {timestamp, ...newData}, { merge: true });
}

export const fetchGameData = async (keyword: string, language: Language, func: (snapshot: DocumentSnapshot<DocumentData>) => void) => {
    const ONE_HOUR = 60 * 60 * 1000;

    const existingGameRef = doc(db, `game_${language}`, keyword );
    const snapshot = await getDoc(existingGameRef);

    if (snapshot.exists()) {
        const gameDuration = new Date().getTime() - snapshot.get('timestamp');

        if (gameDuration > ONE_HOUR) {
            await deleteDoc(existingGameRef);
        }
    }

    onSnapshot(existingGameRef, querySnapshot => func(querySnapshot));
}

export const fetchDictionaries = async (gameKeyword: string, language: Language) => {
    const dictsCollection = collection(db, `dicts_${language}`);
    const snapshot = await getDocs(dictsCollection);
    const index = selectRandomIndex(gameKeyword, snapshot.docs.length - 1);
    return snapshot.docs.map(doc => doc.data()['words'])[index];
}

export const fetchKeywords = async (language: Language) => {
    const keywordsRef = doc(db, `keywords_${language}`, 'keywords' );
    const snapshot = await getDoc(keywordsRef);

    return snapshot.data()?.['words'];
}
export const logGoogleEvent = (eventName: string) => logEvent(analytics, eventName);
