import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import "firebase/auth";
import "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBS7xc3KuSKBkdvTLtGie1MYJwZVL816w4",
    authDomain: "codenames-ts.firebaseapp.com",
    projectId: "codenames-ts",
    storageBucket: "codenames-ts.appspot.com",
    messagingSenderId: "663610989123",
    appId: "1:663610989123:web:e1d052493a44369bdfd2f6",
    measurementId: "G-WGPCZV68W6"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const analytics = getAnalytics();
