import clsx from "clsx";
import classes from "./TopBanner.module.css";
import colors from '../../../Colors.module.css';
import {LanguageSelector} from "../../LanguageSelector/LanguageSelector";
import {RulesButton} from "../../Rules/RulesButton";
import {FullscreenButton} from "../../Fullscreen/FullscreenButton";
import React, {useContext} from "react";
import {LanguageContext} from "../../../App";
import {Language, Role, Team} from "../../../constants";


export const TopBanner: React.FC<{
    isBlackWordClicked: boolean,
    currentTeam: Team,
    winner: Team | null,
    onSetLanguage: (language: Language) => void,
    onRulesClick: () => void,
    onNewGameStart: () => void,
    onTeamChange: () => void,
    playerRole: Role,
    clicksCurrentRound: number}
> = (
    {
        isBlackWordClicked,
        currentTeam,
        winner,
        onSetLanguage,
        onRulesClick,
        onNewGameStart,
        onTeamChange,
        playerRole,
        clicksCurrentRound
    }) => {

    const TEXTS = useContext(LanguageContext);

    return (
        <div className={clsx(classes.topBanner, isBlackWordClicked ? colors.black : colors[currentTeam])}>
            <div className={classes.container}>
                    <span className={classes.status}>
                {isBlackWordClicked
                    ? TEXTS.blackWordClicked
                    : winner ? TEXTS.winner[currentTeam] : TEXTS.yourTurn[currentTeam]
                }
                </span>
                {(playerRole === Role.Player && clicksCurrentRound > 0 && !winner && !isBlackWordClicked) &&
                <button className={classes.button} onClick={onTeamChange}>{TEXTS.endTurn}</button>}
            </div>
            <div className={classes.container}>
                <button className={classes.button} onClick={onNewGameStart}>{TEXTS.newGame}</button>
                <LanguageSelector onSetLanguage={onSetLanguage}/>
                <RulesButton onRulesClick={onRulesClick}/>
                <FullscreenButton/>
            </div>
        </div>
    )
}
