import classes from "./WordsGrid.module.css";
import React from "react";
import clsx from "clsx";
import {Color, Role} from "../../../constants";
import colors from '../../../Colors.module.css';
import {WordItem} from "../GameField";

export const WordsGrid: React.FC<{
    gameData: WordItem[],
    onWordClick: (index: number) => void,
    playerRole: Role
}> = ({gameData, onWordClick, playerRole}) => {
    const getClassNames = (wordData : WordItem) => clsx(classes.word, {
        [colors[wordData.color]]: (playerRole === Role.Captain && wordData.color !== Color.White) || wordData.clicked,
        [colors.transparent]: playerRole === Role.Captain && wordData.clicked && wordData.color !== Color.White
    });

    return <div className={classes.field}>
        {gameData.map((data, index) => <div
            id={index.toString()}
            key={data.word}
            className={getClassNames(data)}
            onClick={() => onWordClick(index)}
        >
            {data.word}
        </div>)}
    </div>
}
