import Chance from "chance";
import {Color, GAME_CONFIG, Language, Team} from '../constants';
import {fetchDictionaries} from '../firebaseActions';
import {WordItem} from "../components/GameField/GameField";

export const generateGame = async (gameKeyword: string, language: Language): Promise<[WordItem[], Team]> => {
    const dictionary = await fetchDictionaries(gameKeyword, language);

    const chance = new Chance(gameKeyword);
    const colorOptions: any = chance.shuffle(Object.values(Team));
    const colors = chance.shuffle([
        Color.Black,
        ...Array((GAME_CONFIG.fieldSize - GAME_CONFIG.whiteWordsCount) / 2).fill(colorOptions[0]),
        ...Array((GAME_CONFIG.fieldSize - GAME_CONFIG.whiteWordsCount) / 2 - 1).fill(colorOptions[1]),
        ...Array(GAME_CONFIG.whiteWordsCount).fill(Color.White)
    ]);

    const words: any[] = chance.shuffle(dictionary).slice(0, GAME_CONFIG.fieldSize);
    const gameData = words.map((word: string, index: number) => ({word, color: colors[index]}));
    return [gameData, colorOptions[0]];
}

export const selectRandomIndex = (gameKeyword: string, maxIndex: number) => {
    const chance = new Chance(gameKeyword);
    return chance.integer({min: 0, max: maxIndex});
}

export const generateKeyword = (words: string[]) => {
    const index = Math.floor(Math.random() * words.length);
    return words[index];
}
