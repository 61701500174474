import classes from './LanguageSelector.module.css';
import logoEn from '../../images/lang-en.png';
import logoRu from '../../images/lang-ru.png';
import React from "react";
import {Language} from "../../constants";

export const LanguageSelector: React.FC<{onSetLanguage: (language: Language) => void}> = ({onSetLanguage}) => {

    return <div className={classes.selector}>
        <img src={logoEn} alt="flag-en" onClick={() => onSetLanguage(Language.En)}/>
        <img src={logoRu} alt="flag-ru" onClick={() => onSetLanguage(Language.Ru)}/>
    </div>
}
