import classes from "./BottomBanner.module.css";
import colors from '../../../Colors.module.css';
import {Team} from "../../../constants";
import React, {useContext} from "react";
import {LanguageContext} from "../../../App";

const WordsCounter: React.FC<{
    color: Team,
    onGetWordsCount: (color: Team) => number
}> = ({color, onGetWordsCount}) => {
    return <div className={`${classes.wordsCounter} ${colors[color]}`}>
        {onGetWordsCount(color)}
    </div>
}

export const BottomBanner: React.FC<{
    onGetWordsCount: (color: Team) => number
}> = ({onGetWordsCount}) => {
    const TEXTS = useContext(LanguageContext);

    return <div className={classes.bottomBanner}>
        <WordsCounter color={Team.Red} onGetWordsCount={onGetWordsCount}/>
        <div className={classes.wordsRemaining}>{TEXTS.wordsRemaining}</div>
        <WordsCounter color={Team.Blue} onGetWordsCount={onGetWordsCount}/>
    </div>
}
