export const LOCAL_STORAGE_KEY = 'codenames_language';
export const GAME_CONFIG = {
    fieldSize: 25,
    whiteWordsCount: 7
}

export enum Language {
    En = 'en',
    Ru ='ru'
}

export enum Team {
    Red = 'red',
    Blue = 'blue'
}

export enum Role {
    Captain = 'captain',
    Player = 'player'
}

export enum Color {
    White = 'white',
    Black = 'black'
}

export type Colors = Team | Color;

export type Texts = {
    language: Language,
    keyword: string,
    role: {
        [key in Role]: string
    },
    yourTurn: {
        [key in Team]: string
    },
    winner: {
        [key in Team]: string
    },
    newGame: string,
    rules: string,
    changeKey: string,
    toGame: string,
    wordsRemaining: string,
    blackWordClicked: string,
    endTurn: string
}

export const TEXTS: {[key in Language]: Texts} = {
    [Language.Ru]: {
        language: Language.Ru,
        role: {
            [Role.Captain]: 'капитан',
            [Role.Player]: 'игрок'
        },
        yourTurn: {
            [Team.Red]: 'Ход команды красных',
            [Team.Blue]: 'Ход команды синих'
        },
        winner: {
            [Team.Red]: 'Выиграла команда красных',
            [Team.Blue]: 'Выиграла команда синих'
        },
        keyword: 'Ключевое слово',
        newGame: 'Новая игра',
        rules: 'Правила',
        changeKey: 'Сгенерировать другой ключ',
        toGame: 'Открыть игру как:',
        wordsRemaining: 'Слов осталось',
        blackWordClicked: 'Команда, нажавшая черное слово, проиграла',
        endTurn: "Завершить ход",
    },
    [Language.En]: {
        language: Language.En,
        role: {
            [Role.Captain]: 'captain',
            [Role.Player]: 'player'
        },
        yourTurn: {
            [Team.Red]: 'Team Red - your turn',
            [Team.Blue]: "Team Blue - your turn"
        },
        winner: {
            [Team.Red]: 'Team Red won',
            [Team.Blue]: 'Team Blue won'
        },
        keyword: 'Keyword',
        newGame: 'New game',
        rules: 'Rules',
        changeKey: 'Generate another key',
        toGame: 'Open game as:',
        wordsRemaining: 'Words remaining',
        blackWordClicked: 'The team that hit the black word has lost.',
        endTurn: "End turn"
    },
}
