import {Role} from "../../constants";
import React, {useContext} from "react";
import classes from './RoleSelectModal.module.css';
import {LanguageContext} from '../../App';

export const RoleSelect: React.FC<{onRoleSelect: (role: Role) => void}> = ({onRoleSelect}) => {

    const TEXTS = useContext(LanguageContext);

    return <>
        <div className={classes.backdrop}/>
        <div className={classes.selectRole}>
            <div className={classes.label}>{TEXTS.toGame}</div>
            <button className={classes.selectRoleButton}
                    onClick={() => onRoleSelect(Role.Captain)}>{TEXTS.role[Role.Captain]}</button>
            <button className={classes.selectRoleButton}
                    onClick={() => onRoleSelect(Role.Player)}>{TEXTS.role[Role.Player]}</button>
        </div>
    </>
}
